import './App.css';
import Header from './includes/Header';
import Footer from './includes/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages';
import About from './pages/about.js';
import Projects from './pages/projects.js';
import Contact from './pages/contact.js';


function App() {
  return (
  
    <Router>
      <Header />
      <Routes>
      <Route exact path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route
                    path="/contact"
                    element={<Contact />}
                />
                <Route path="/projects" element={<Projects />} />
      </Routes>
      <Footer />
    </Router>

  );
}

export default App;
