import React from "react";
import { SocialIcon } from 'react-social-icons'


const Contact = () => {
    return (
        <div class="main">
            <div class="container left">
                <div class="centered">
                    <h1>Contact me</h1>
                    <SocialIcon class="icon" url="https://www.linkedin.com/in/hannah-tomlinson-39701b188/" target="_blank"/>
                    <SocialIcon class="icon" url="https://github.com/hannahrose76" target="_blank"/>
                </div>
            </div>
            <div class="container right">
                <div class="centered">
                    <iframe id="map" title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d75496.90324144755!2d-3.083817669165821!3d53.748928744154945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b402d5a3f1869%3A0xfb31e87322fd8f2c!2sLytham%20Saint%20Annes%20FY8!5e0!3m2!1sen!2suk!4v1719169664191!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>


        </div>
    );
};
 
export default Contact;