import '../style/footer.css';
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
    <footer>
    <p> &copy; {currentYear} Hannah Tomlinson</p>
    </footer>
    </>
  );
}

export default Footer;
