import React from "react";
import portrait from "../imgs/portrait.jpg"
const About = () => {
    return (
        <div class="main">
            <div class="container left">
                <div class="centered">
                    <div id="centered-top">
                        <img id="portrait" src={portrait} alt="Hannah" class="image" />
                        <div class="about">
                            <h1>About me</h1>
                            <p>Teacher, Developer, Guide.</p>
                        </div>
                    </div>
                    <section>
                    <p>After achieving a first-class BSc with honours in website development at UCLan, I worked as a front-end website developer for 1 year.  I then decided to pursue my lifelong goal of becoming a teacher.</p>
                    
                    <p>I plan and deliver an ambitious curriculum across several courses that encompasses the knowledge, skills and behaviours that learners need, reflecting the needs of local businesses.
                    </p>
                    <p>I currently teach in FE on a range of digital courses including BTEC IT as well as T-Level Digital Production, Design and Development. From this role, and the ever-changing world of IT, I enjoy learning something new every day.</p>
                    </section>
                </div>
            </div>
            <div class="container right">
                <div class="centered">
                    <h1>Skills</h1>
                    <subheading>Website Development</subheading>
                    <ul>
                        <li>UX & UI Design</li>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JavaScript</li>
                        <li>React</li>
                        <li>PHP</li>
                        <li>Django</li>
                    </ul>
                    <subheading>Programming</subheading>
                    <ul>
                        <li>Problem Solving</li>
                        <li>Flow Diagrams</li>
                        <li>Python</li>
                    </ul>
                    <subheading>Digital Marketing</subheading>
                    <ul>
                    <li>Developing a marketing strategy</li>
                        <li>Copywriting</li>
                        <li>Content creation</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
 
export default About;