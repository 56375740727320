import React from "react";
import { NavLink } from 'react-router-dom';
import kit from "../imgs/kit.PNG"
import payments4travel from "../imgs/payments4travel.png"
 
const Home = () => {
    return (
        <>
        <div class="main">
            <div class="container left">
                <div class="centered">
                    <h1>Teacher of Digital Technologies</h1>
                    <p>Let's prepare for a dynamic digital future.</p>
                    <NavLink to="/about" className="cta">About<span class="material-symbols-outlined">arrow_forward_ios</span></NavLink>
                </div>
            </div>
            <div class="container right">
                <div class="centered">
                    <img src={kit} alt="UCLan Kit" class="image" />
                    <img src={payments4travel} alt="Payments4Travel" class="overlay" />
                    <NavLink id="project-link" class="link" to="/projects">View projects</NavLink>
                </div>
            </div>
        </div>
        
        </>
    );
};
 
export default Home;