import React from "react";
import kit from "../imgs/kit.PNG"
import iot from "../imgs/iot.jpg"
import iotmenu from "../imgs/iotmenu.png"
import payments4travel from "../imgs/payments4travel.png"
import photographer from "../imgs/photographer.png"
import fieldstone from "../imgs/fieldstone.png"
import viking from "../imgs/viking.png"
import supermarket from "../imgs/supermarket.jpg"
const Projects = () => {
    return (
        <div class="main">
           <div class="row">
           <div class="column">
                <img src={kit} alt="UCLan Kit" width="600" height="auto"/>
                <div class="desc">UCLan Kit Search App Wireframe</div>
            </div>
            <div class="column">
                <img src={payments4travel} alt="Payments4Travel" width="600" height="auto"/>
                <div class="desc">Payments4Travel Site</div>
            </div>
            <div class="column">
                <img src={iot} alt="RaspberryPi" width="600" height="auto"/>
                <div class="desc">RaspberryPi with LED</div>
            </div>
            <div class="column">
                <img src={iotmenu} alt="IOT" width="600" height="auto"/>
                <div class="desc">Internet of Things LED Control</div>
            </div>
            <div class="column">
                <img src={fieldstone} alt="Fieldstone Gardens" width="600" height="auto"/>
                <div class="desc">Fieldstone Gardens Site</div>
            </div>
            <div class="column">
                <img src={photographer} alt="The Weekend Photographer" width="600" height="auto"/>
                <div class="desc">The Weekend Photographer Site</div>
            </div>
            <div class="column">
                <img src={viking} alt="Viking Productions" width="600" height="auto"/>
                <div class="desc">Viking Productions Site</div>
            </div>
            <div class="column">
                <img src={supermarket} alt="Supermarket App" width="600" height="auto"/>
                <div class="desc">Universal Supermarket App Prototype</div>
            </div>
        </div>
        </div>
    );
};
 
export default Projects;