import '../style/header.css';
import addResponsive from '../scripts/navbar';
import { NavLink } from 'react-router-dom';
function Header() {
    return (
        <>
        <head>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@300&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <script src="https://kit.fontawesome.com/fd5c56eabc.js" crossorigin="anonymous"></script>
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>

        </head>
        <div class="topnav" id="myTopnav">
            
        <NavLink className="home" activeClassName="active" to="/">Hannah Tomlinson</NavLink >

        <div class="right-nav">
            <NavLink activeClassName="active" to="/about">About</NavLink >
            <NavLink activeClassName="active" to="/projects">Projects</NavLink >
            <NavLink activeClassName="active" to="/contact">Contact</NavLink >
            <button class="icon" onClick={addResponsive}
            >
                <i class="fa fa-bars" style={{fontSize: 2 + "em"}}></i>
            </button>
        </div>
        </div>
        </>
    );
  }
  
  export default Header;
  